@import "src/styles";
.container {
  position: relative;

  & .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $form-field-height;
    width: 100%;
    padding: 0 $spacing-16;
    background-color: $color-white;
    border: $form-field-border;
    border-radius: $form-field-radius;
    cursor: pointer;
    user-select: none;

    & [data-select-trigger-value] {
      display: flex;
    }

    & .chevronDown {
      margin-left: auto;
      transition: transform 250ms ease-in;
      fill: $color-primary-main;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      & .chevronDown {
        fill: $color-gray-200;
      }
    }
  }
}
