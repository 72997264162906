@import "src/styles";
$icon-size: 24px;

.input {
  @include input();
  width: 100%;
  padding: {
    right: $spacing-16 + $icon-size + $spacing-8;
    left: $spacing-16;
  }

  &[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.invalid {
    border-color: $color-warning-main;
    color: $color-warning-main;
  }

  &[aria-invalid='true'] {
    background-color: $color-input-error-bg;

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &::-ms-input-placeholder,
    &:-ms-input-placeholder {
      color: $color-warning-main;
    }
  }

  &:focus:not([aria-invalid='true']) + label {
    color: $color-primary-main;
  }

  &.hasValue {
    padding-top: $spacing-16;
  }
}
