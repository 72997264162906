@import "src/styles";
.list {
  position: absolute;
  width: 100%;
  // Trustpilot-Fix:  To avoid dropdown getting overlap with trustpilot widget
  z-index: $z-index-2;
  background-color: $color-white;

  animation: slide-down 300ms ease;
  border: none;
  padding: 0;

  & [data-combobox-list] {
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
    max-height: 6 * $form-field-height;
    overflow-y: auto;

    & > [data-combobox-list-item] {
      height: $form-field-height;
      padding: 0 $spacing-16;
      display: flex;
      align-items: center;
      background-color: $color-white;
      cursor: pointer;

      border: {
        bottom: $form-field-border;
        left: $form-field-border;
        right: $form-field-border;
        top: 1px solid transparent;
      }

      &:hover,
      &[data-combobox-list-item-focused='true'] {
        background: $color-gray-50;
        color: inherit;
      }

      & [data-user-value]:first-child {
        font-weight: bold;
      }

      & span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 0.6;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
