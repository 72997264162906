@import "src/styles";
.container {
  display: grid;
  width: 100%;
  column-gap: $spacing-8;
  row-gap: $spacing-12;
  position: relative;

  &:not(.onlyMobileView) {
    @include breakpoint-md {
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto auto minmax($spacing-24, $spacing-48);
    }

    .label {
      @include breakpoint-md {
        grid-column: 1 / span 12;
        // Trustpilot-Fix:  To avoid widget from getting out of banner when NL language is too long
        white-space: nowrap;
      }
    }

    .combobox {
      @include breakpoint-md {
        grid-column: 1 / span 7;
      }
    }

    .submit {
      @include breakpoint-md {
        grid-column: 8 / span 5;
      }
    }

    .error {
      @include breakpoint-md {
        grid-column: 1 / span 10;
      }
    }
  }
}

.error {
  grid-row: 3 / span 1;
  margin-left: $spacing-16;
}

.combobox {
  & [data-combobox-list-item] {
    & .arrow {
      box-sizing: content-box;
      width: 20px;
      height: 12px;
      fill: $color-primary-main;
      margin-left: auto;
      display: none;
    }
  }
  & [data-combobox-list-item]:hover,
  & [data-combobox-list-item-focused='true'] {
    & .arrow {
      display: block;
    }
  }

  & [aria-invalid='true'] ~ .icon {
    fill: $color-warning-main;
  }

  & .icon {
    box-sizing: content-box;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    fill: $color-primary-main;
    padding-right: $spacing-16;
  }
}
