@import "src/styles";
.container {
  width: 100%;

  fieldset {
    + fieldset {
      margin-top: $spacing-16;
      margin-bottom: $spacing-24;
    }
  }
}

.button {
  transition:
    background-color 100ms ease,
    color 100ms ease-in-out;

  &.blue {
    border-color: $color-white;
    background-color: $color-primary-main;
    color: $color-white;
  }

  .icon {
    transition: fill 100ms ease-in-out;

    &.blue {
      fill: $color-white;
    }
  }
}

.withFlag {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: $spacing-16;
  }
}

.countrySelect {
  svg {
    width: 20px;
  }

  & [data-select-list-item],
  & [data-select-trigger-value] {
    > svg {
      width: 20px;
      margin-right: $spacing-16;
    }
  }
}
