@import "src/styles";
.container {
  & .controlsWrapper {
    position: relative;
    display: flex;

    > div {
      display: flex;
      position: relative;

      svg {
        color: $color-primary-main;
        z-index: $z-index-2;
      }

      select {
        border-radius: 0;
        flex: 1 1 auto;
        height: 40px;
        padding-left: $spacing-16 - 1;
        padding-right: $spacing-32 + $spacing-4;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:focus {
          z-index: $z-index-2;
        }
      }
    }

    > div:first-of-type {
      width: 70%;

      select {
        border-top-left-radius: $form-field-radius;
        border-bottom-left-radius: $form-field-radius;
        margin-right: -1px;
      }
    }

    > div:last-of-type {
      width: 70%;
      select {
        border-top-right-radius: $form-field-radius;
        border-bottom-right-radius: $form-field-radius;
        margin-left: -1px;
      }
    }
  }

  & .error {
    select {
      background-color: $color-input-error-bg;
      border-color: $color-warning-main;
    }
  }

  & .errorMessage {
    font-weight: $font-weight-bold;
    padding: {
      left: $spacing-16;
      right: $spacing-16;
    }
    margin-top: $spacing-4;
    text-align: left;
  }
}
