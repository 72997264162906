@import "src/styles";
$space-left: $spacing-16;
$icon-floating-right: $spacing-16;
$icon-width: 24px;
$icon-spacing: $icon-width + (2 * $icon-floating-right);

.floatingLabel {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 1.53em;
  left: $space-left;
  line-height: 1.14;
  letter-spacing: 0.02em;
  right: $spacing-16;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.floatingLabelIconSpacing {
    right: $icon-spacing - $spacing-8;
  }
}

.inputWrapper {
  position: relative;

  input:placeholder-shown {
    text-overflow: ellipsis;
  }

  & .input {
    &.forceFocus {
      outline: unset;
      border: $form-field-border-focus;
    }
  }
  & .input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  & .input.forceFocus:not([aria-invalid='true']) + .floatingLabel,
  & .input:focus:not([aria-invalid='true']) + .floatingLabel {
    color: $color-primary-main;
    outline: none !important;
  }

  & textarea {
    padding: $spacing-12 $spacing-16;
  }
}

.hasValue .input {
  padding-top: $spacing-16;
}

.floatingLabel[data-shrink='true'] {
  opacity: 1;
  top: 0.74em;
  transform: scaleY(0.95);
  transition:
    top 100ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    scaleY 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.error {
  font-weight: $font-weight-bold;
  padding: {
    left: $space-left;
    right: $space-left;
  }
  margin-top: $spacing-4;
  text-align: left;
}

.icon {
  fill: $color-primary-main;
  @extend .input-icon;
}

.withIcon {
  padding-right: $icon-spacing;
}

.input-icon {
  position: absolute;
  width: $icon-width;
  height: $icon-width;
  top: 50%;
  transform: translateY(-50%);
  right: $icon-floating-right;
}

.iconTooltip {
  @extend .input-icon;
  & .fakeIcon {
    width: $icon-width;
    height: $icon-width;
  }
}
