@import "src/styles";
.list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 5 * $form-field-height;
  background-color: $color-white;
  border: {
    bottom: $form-field-border;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    border: {
      right: $form-field-border;
    }
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background-color: $color-gray-200;
    background-clip: content-box;
  }

  & .item {
    height: $form-field-height;
    padding: 0 $spacing-16;
    display: flex;
    background-color: $color-white;
    outline: none;
    align-items: center;
    user-select: none;
    border: {
      bottom: $form-field-border;
      left: $form-field-border;
      right: $form-field-border;
      top: 1px solid transparent;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
    }

    &.focused {
      background: $color-gray-50;
    }

    &:hover:not([aria-disabled='true']) {
      background: $color-gray-50;
      &[aria-selected='true'] {
        background: $color-gray-50;
      }
    }

    &[aria-selected='true'] {
      font-weight: $font-weight-bold;
    }
    &[aria-disabled='true'] {
      background-color: $color-gray-100;
      color: $color-gray-200;
      cursor: not-allowed;
    }
  }
}
