@import "src/styles";
.container {
  width: 100%;
  position: relative;

  &.valueIcon {
    svg:first-child {
      width: 20px;
      position: absolute;
      top: 50%;
      left: $spacing-16;
      transform: translateY(-50%);
    }
  }
}

.select {
  @include input;
  @include levelTypography(level5);

  & {
    padding: 0 $spacing-16;
    display: block;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  &.valueIcon {
    padding-left: 20px + 2 * $spacing-16;
  }
}

.chevronDown {
  pointer-events: none;
  width: 20px;
  right: $spacing-16;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
